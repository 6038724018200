/* eslint-disable arrow-body-style */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Typography, Button, useWindowSize } from "design_system/src";
import { ReactComponent as Right } from "design_system/src/static/icons/chevron-right.svg";

import Countdown, {CountdownRendererFn, CountdownRenderProps} from "react-countdown";

// images
import venomImg from "static/images/venomania/banner_venom.png";
import bannerBaseDesktopImg from "static/images/venomania/banner_base_desktop.png";
import bannerBaseMobileImg from "static/images/venomania/banner_base_mobile.png";
import bannerHeadingImg from "static/images/venomania/banner_heading.png";
import bannerMotionLeft from "static/images/venomania/banner_motion_left.png";
import bannerMotionRight from "static/images/venomania/banner_motion_right.png";

import "./oneThousand.scss";
import { useDispatch } from "react-redux";
import {
  clearCreditVerificationCreditTimeAction,
  clearCreditVerificationVehicleAction,
} from "store/actions/creditVerification";


export const OneThousandSection = () => {
  const { width: windowWidth } = useWindowSize();
  const targetDate = Date.parse(`${process.env.REACT_APP_TARGET_DATE_BANNER}`);
  const targetEndedDate = process.env.REACT_APP_TARGET_ENDED_DATE_BANNER
    ? Date.parse(`${process.env.REACT_APP_TARGET_ENDED_DATE_BANNER}`)
    : null;
  const [timeClass, setTimeClass] = useState<string>("initial");
  const [showEndCountdown, setShowEndCountdown] = useState<boolean>(false);
  const [endedCountdown, setEndedCountdown] = useState<boolean>(false);

  const dispatch = useDispatch();

  const showModal = () => {
    dispatch(clearCreditVerificationCreditTimeAction());
    dispatch(clearCreditVerificationVehicleAction());
  };

  const formatTimeNumber = (num: number): string => {
    return String(num).padStart(2, "0");
  };

  const renderButtons = () => {
    return (
      <div className="display_flex flex_col_mobile flex_justify_center flex_align_center_mobile flex_align_end_desktop flex_gap_xs_mobile flex_gap_xl_desktop p_y_xl_mobile p_b_xxxl_desktop">
        <Link to="/financia-tu-moto" onClick={showModal}>
          <Button
            variant="principal"
            scale={windowWidth > 800 || windowWidth < 600 ? "small" : "small"}
          >
            <Typography weight="600" scale="medium">¡Solicita tu crédito ahora!</Typography>
          </Button>
        </Link>
        <Link to="/catalogo">
          <Button
            color="grey"
            orientation="right"
            scale={windowWidth > 800 || windowWidth < 600 ? "small" : "small"}
            icon={<Right />}
          >
            <Typography weight="600" scale="medium">Ver catálogo de motos</Typography>
          </Button>
        </Link>
      </div>
    );
  };

  const startCountdownRenderer: CountdownRendererFn = ({ days, hours, minutes, seconds, completed }: CountdownRenderProps) => {
    if (completed) {
      return <div className="counterDownCompleteCountainer">
        <Typography scale="heading3" weight="600" textColor="neutral_0">Descuentos de hasta</Typography>
        <Typography scale="heading1" weight="600" textColor="primary_300">$10,000 pesos</Typography>
      </div>;
    }

    if (days === 1 && hours === 0 && minutes === 0 && seconds === 0) {
      setTimeClass("lastDay");
    } else if (days === 2 && hours === 0 && minutes === 0 && seconds === 0) {
      setTimeClass("lastTwoDays");
    }

    return (
      <div className="counterDownCountainer">
        <Typography scale="large" scaleMobile="medium" weight="400" className="text_center">Faltan</Typography>
        <div className="display_flex">
          <div className="counterDownTime display_flex flex_col flex_justify_end flex_align_center">
            <Typography scale="heading1" scaleMobile="heading3" weight="600">{formatTimeNumber(days)}</Typography>
            <Typography scale="large" scaleMobile="medium" weight="400">DÍAS</Typography>
          </div>
          <div>
            <Typography scale="heading1" weight="600" textColor="primary_300">:</Typography>
          </div>
          <div className="counterDownTime display_flex flex_col flex_justify_end flex_align_center">
            <Typography scale="heading1" scaleMobile="heading3" weight="600">{formatTimeNumber(hours)}</Typography>
            <Typography scale="large" scaleMobile="medium" weight="400">HR</Typography>
          </div>
          <div>
            <Typography scale="heading1" weight="600" textColor="primary_300">:</Typography>
          </div>
          <div className="counterDownTime display_flex flex_col flex_justify_end flex_align_center">
            <Typography scale="heading1" scaleMobile="heading3" weight="600">{formatTimeNumber(minutes)}</Typography>
            <Typography scale="large" scaleMobile="medium" weight="400">MIN</Typography>
          </div>
          <div>
            <Typography scale="heading1" weight="600" textColor="primary_300">:</Typography>
          </div>
          <div className="counterDownTime display_flex flex_col flex_justify_end flex_align_center">
            <Typography scale="heading1" scaleMobile="heading3" weight="600">{formatTimeNumber(seconds)}</Typography>
            <Typography scale="large" scaleMobile="medium" weight="400">SEG</Typography>
          </div>
        </div>
      </div>
    );
  };

  const endCountdownRenderer: CountdownRendererFn = ({ days, completed }: CountdownRenderProps) => {
    if (completed) {
      return <div/>;
    }

    return (
      <div className="counterDownCountainer counterDownDays pos_absolute">
        <div className="display_flex">
          <Typography scale="large" scaleMobile="medium" weight="400" className="display_flex flex_align_center flex_gap_sm">
            {days + 1 === 1 ? "Queda" : "Quedan"} <span className="text_primary_300 text_large_600_mobile text_heading1_600_desktop">{days + 1}</span> <span className="text_primary_300 text_medium_600_mobile text_large_600_desktop">{days + 1 === 1 ? "DÍA" : "DÍAS"}</span> de la
          </Typography>
        </div>
      </div>
    );
  };

  const onStartCountdownRenderer = () => {
    setTimeClass("final");
    if (targetEndedDate) {
      setShowEndCountdown(true);
      setEndedCountdown(true);
    }
  };

  const onEndedCountdownComplete = () => {
    setEndedCountdown(false);
  };

  return (
    <section className="bannerSection">
      <div className="p_x_none pos_relative">
        <img
          src={bannerBaseMobileImg}
          alt="Banner landing page"
          className={`bannerBaseImg ${timeClass} display_none_desktop w_100_per_mobile`}
        />
        <img
          src={bannerBaseDesktopImg}
          alt="Banner landing page"
          className={`bannerBaseImg ${timeClass} display_none_mobile w_100_per_desktop`}
        />
        <div className="bannerContainer p_x_none pos_absolute w_100_per_mobile w_100_per_desktop banner-buttons">
          <div className="display_flex flex_col_reverse_mobile flex_justify_between h_100_per_mobile h_100_per_desktop">
            <div className="display_none_desktop">{renderButtons()}</div>
            <div className="w_45_per_desktop h_50_per_mobile h_100_per_desktop pos_relative">
              <img
                src={bannerMotionLeft}
                alt="Se acerca la venomania"
                className={`bannerMotion bannerMotionLeft ${timeClass}`}
              />
              <img
                src={bannerMotionRight}
                alt="Se acerca la venomania"
                className={`display_none_desktop bannerMotion bannerMotionRight ${timeClass}`}
              />
              <img
                src={venomImg}
                alt="Moto venom"
                className="venomImg"
              />
            </div>
            <div className="w_55_per_desktop h_50_per_mobile pos_relative">
              <img
                src={bannerMotionRight}
                alt="Se acerca la venomania"
                className={`display_none_mobile bannerMotion bannerMotionRight ${timeClass}`}
              />
              <div className="w_100_per pos_absolute h_100_per_desktop display_flex flex_col flex_justify_between_mobile flex_center_desktop flex_align_center">
                <div className="display_flex flex_col w_80_per flex_align_center p_t_xl_mobile p_t_xxxl_desktop">
                  {showEndCountdown && targetEndedDate &&
                    <Countdown
                      date={targetEndedDate}
                      renderer={endCountdownRenderer}
                      onComplete={onEndedCountdownComplete}
                    />
                  }
                  <img
                    src={bannerHeadingImg}
                    alt="Venomania"
                    className="bannerHeading w_100_per"
                    style={{
                      zIndex: endedCountdown ? 2 : 1,
                      marginTop: endedCountdown ? 40 : 0,
                    }}
                  />
                  <Countdown
                    date={targetDate}
                    renderer={startCountdownRenderer}
                    onComplete={onStartCountdownRenderer}
                  />
                </div>
                <div className="display_none_mobile">{renderButtons()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
